import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import '@mod-system/js/wh/integration';
import 'dompack/browserfix/reset.css';
import * as util from './shared/js/utilities';
import * as wrdauth from '@mod-wrd/js/auth';

// vendor
import 'swiper/swiper-bundle.min.css';
import $ from 'jquery';
import * as rpc from './data.rpc.json';
import Dialog from 'share-dialog';

import './shared/rtd/rtd';
import './shared/forms/forms';

import VideoPlayersController from './shared/js/videos';

import './org2020.scss';
import './rotary-pwa.scss';
import './web/css/org001.css';

import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';
openLinksInNewWindow();

// shared (global) elements
import './header/header';
import './footer/footer';
import './menubar/menubar';

import './magazine';

import * as page from './shared/js/page';

// pages
import './pages/clubfinder/clubfinder';
import './pages/rotaryhelpt/rotaryhelpt';
import './pages/mystream/mystream';
import './pages/myprofile/myprofile';
import './pages/annonceorder/annonceorder';
import './pages/appsearch/appsearch';
import './pages/appperson/appperson';
import './pages/apporganization/apporganization';
import './pages/appactivities/appactivities';
import './pages/appemail/appemail';
import './pages/appmembershipcard/appmembershipcard';
import './pages/appbirthdays/appbirthdays';
import './pages/appactivityattendances/appactivityattendances';
import './pages/event/event';
import './pages/webshop/webshop';
import basketpanel from './pages/webshop/webshop';

// components
import './components/sidebar/sidebar';
import * as mobilemenu from './components/mobilemenu/mobilemenu';
import SplitH1Nav from './components/splith1nav/splith1nav';

// widgets
import './widgets/widgets';

// Load the core build.
//var _ = require('lodash/core');
//import _ from 'lodash/core';

var deferredPWAPrompt;

// in pwa redirect to /leden/
window.___isInStandaloneMode = (window.navigator.standalone) || (window.matchMedia('(display-mode: standalone)').matches) || document.referrer.includes('android-app://');
if (window.___isInStandaloneMode) {
  //if (window.location.pathname === '/') {
  //  console.log('redirect');
  //  window.location.href = '/leden/';
  //}
}

function isiOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

if (isiOS()) {
  $("html").addClass("iOS");
}

function getiOSversion() {

  if (iOS) { // <-- Use the one here above
    if (window.indexedDB) { return 'iOS 8 and up'; }
    if (window.SpeechSynthesisUtterance) { return 'iOS 7'; }
    if (window.webkitAudioContext) { return 'iOS 6'; }
    if (window.matchMedia) { return 'iOS 5'; }
    if (window.history && 'pushState' in window.history) { return 'iOS 4'; }
    return 'iOS 3 or earlier';
  }

  return 'Not an iOS device';
}

//console.log("config", whintegration.config);

function getScrollPositionForElement($el) {
  if (!$el || $el.length === 0) {
    console.error('no element');
    return 0;
  }

  const $topbar = $('.topbar');
  let scrollOffset = 0;
  if ($topbar.css('position') === 'fixed') {
    scrollOffset = $topbar.get(0).getBoundingClientRect().height;
  }

  return $el.get(0).getBoundingClientRect().top - scrollOffset;
}

function onScroll(ev) {
  let scrollY = util.getScrollY();

  // show the 'scroll to top' button after scrolling a bit
  dompack.toggleClass(document.documentElement, 'show-scroll-to-top', scrollY > 50);

  // fade out the hero image title
  dompack.toggleClass(document.documentElement, 'hide-heroimage-title', scrollY > 130);
}

function setupMobileSearchContainer() {
  $('.js-toggle-mobile-search').click(function(evt) {
    evt.preventDefault();
    $('html').toggleClass('mobile-search-open');
    mobilemenu.closeMenu();
  });
}

function shareEvent(evt, url, type) {
  if (evt) {
    evt.preventDefault();
  }

  switch (type) {
    case 'twitter':
      Dialog.twitter(url, whintegration.config.obj.filetitle).open();
      break;
    case 'facebook':
      Dialog.facebook(url).open();
      break;
    case 'linkedin':
      Dialog.linkedIn(url, whintegration.config.obj.filetitle).open();
      break;
    case 'whatsapp':
      window.open(`https://wa.me/?text=${encodeURIComponent(url)}`);
      break;
  }
}

function setupSharePage() {
  $('.js-share-page-twitter').click(function(evt) { shareEvent(evt, whintegration.config.obj.fileurl, 'twitter'); });
  $('.js-share-page-facebook').click(function(evt) { shareEvent(evt, whintegration.config.obj.fileurl, 'facebook'); });
  $('.js-share-page-linkedin').click(function(evt) { shareEvent(evt, whintegration.config.obj.fileurl, 'linkedin'); });
  $('.js-share-page-whatsapp').click(function(evt) { shareEvent(evt, whintegration.config.obj.fileurl, 'whatsapp'); });
}

function setupAnchorNavigation(reset = false) {
  handleTitleH1(); // fixme: jannes: if splith1nav then setupAnchorNavigation is called twice
  if (!document.documentElement.classList.contains('enable-h2-navigation')) {
    return;
  }

  const $anchorNav = $('#contentwrapper-anchornav');
  if (reset === true) {
    $anchorNav.empty();
  }

  const $h2s = $('#contentwrapper-contents > h2:visible,#contentwrapper-contents h2.can-nav:visible');
  if ($h2s.length == 0) {
    $anchorNav.hide();
    return;
  }

  $anchorNav.show();

  $h2s.each(function(idx) {
    const $link = $(`<a href="#">${$(this).text()}</a>`);
    $link.click((evt) => {
      evt.preventDefault();
      $('html, body').animate({ scrollTop: getScrollPositionForElement($(this)) }, 600);
    });

    $anchorNav.append($link);
    if (idx < $h2s.length - 1) {
      $anchorNav.append('<span class="contentwrapper__anchornav-separator">|</span>');
    }
  });
}

function addPWAFooter() {
    let navpwa = '';
    navpwa = navpwa + '<nav class="nav-pwa">';
    navpwa = navpwa + '  <ul>';
    navpwa = navpwa + '    <li class="navitem"><a href="/leden/" title="Naar het beginscherm / activity-stream"><i class="fas fa-home"></i><h2>Home</h2></a></li>';
    navpwa = navpwa + '    <li class="navitem"><a href="/leden/app/search.html" title="Naar zoeken" class="navitem"><i class="fas fa-search"></i><h2>Zoeken</h2></a></li>';
    navpwa = navpwa + '    <li class="navitem"><a href="/leden/app/activities/" title="Naar activiteiten" class="navitem"><i class="fas fa-calendar-alt"></i><h2>Activiteiten</h2></a></li>';
    navpwa = navpwa + '    <li class="navitem"><a href="/leden/profiel.shtml" title="Naar mijn profiel" class="navitem"><i class="fas fa-id-card"></i><h2>Profiel</h2></a></li>';
    //navpwa = navpwa + '    <li class="navitem myclub"><a href="https://www.rotarynew.wevolve.nl/amsterdamnieuwendam/Voorbeeld_map/" title="Naar overige items" class="navitem"><i class="fas fa-list"></i><h2>Meer</h2></a></li>';
    navpwa = navpwa + '    <li class="navitem myclub"><a href="/leden/" title="Naar mijn club" class="navitem"><i class="fas fa-list"></i><h2>Mijn club</h2></a></li>';
    navpwa = navpwa + '  </ul>';
    navpwa = navpwa + '</nav>';
    $('body').append(navpwa);
}

function addPWAHeader() {
    let navpwaheader = '';
    navpwaheader = navpwaheader + '<div class="nav-pwa-tmp">';
    //navpwaheader = navpwaheader + '<nav class="nav-pwa-header-home">';
    //navpwaheader = navpwaheader + '<img src="/resources/img/rotary-logo.svg" />';
    //navpwaheader = navpwaheader + '</nav>';
    navpwaheader = navpwaheader + '<nav class="nav-pwa-header">';
    //navpwaheader = navpwaheader + '<span class="left"><a href="javascript:history.back();" title="Terug"><i class="fas fa-angle-left"></i></a></span>';
    navpwaheader = navpwaheader + '<span class="left actions flex-items">';
    if (window.location.pathname !== '/leden/') {
      navpwaheader = navpwaheader + '<a class="action" href="javascript:history.back();" title="Terug"><i class="fas fa-less-than"></i></a>';
    }
    navpwaheader = navpwaheader + '<a class="action" id="actionrefresh" href="" title="Opnieuw laden"><i class="fas fa-redo"></i></a>';
    navpwaheader = navpwaheader + '</span>';

    navpwaheader = navpwaheader + '<span class="center">';
    navpwaheader = navpwaheader + '<div class="pwa-header__logocontainer logo-and-name"><a class="pwa-header__logolink" href="/"><div class="pwa-header__logoimg">';
    navpwaheader = navpwaheader + '<img class="rotary" src="/resources/img/rotary-logo.svg" />';
    navpwaheader = navpwaheader + '<img class="rotaract" src="/resources/img/rotaract-logo-simple.svg" />';
    navpwaheader = navpwaheader + '</div><p>in Nederland</p></a><div class="menu"></div></div>';
    navpwaheader = navpwaheader + '</span>';

    //navpwaheader = navpwaheader + '<span class="title"><img class="ri-wheel" src="/resources/img/rotary-logo-wheel.svg" alt=""/><h2>Title here</h2></span>';
    navpwaheader = navpwaheader + '<span class="right">';
    //navpwaheader = navpwaheader + '<a href="" title=""><i class="fas fa-times"></i></a>';
    //navpwaheader = navpwaheader + '<a href="" title=""><i class="fas fa-plus"></i></a>';
    //navpwaheader = navpwaheader + '<a href="" title=""><i class="fas fa-edit"></i></a>';
    if (window.location.pathname === '/leden/') {
      //navpwaheader = navpwaheader + '<a href="" title=""><i class="fas fa-filter"></i></a>';
    }
    navpwaheader = navpwaheader + '</span>';
    navpwaheader = navpwaheader + '</nav>';
    //navpwaheader = navpwaheader + '<nav class="nav-pwa-header">';
    //navpwaheader = navpwaheader + '<span class="left"><a href="" title="Terug"><i class="fas fa-arrow-left"></i></a></span>';
    //navpwaheader = navpwaheader + '<h2>Title here</h2>';
    //navpwaheader = navpwaheader + '<span class="right text"><a href="" title="">opslaan</a>&nbsp;<a href="" title="">bewerken</a></span>';
    //navpwaheader = navpwaheader + '</nav>';
    navpwaheader = navpwaheader + '</div>';
    $('body').prepend(navpwaheader);
}

var visibilitysetup = true;
var didblur = false;

dompack.onDomReady(() => {
//console.log(basketpanel);
//basketpanel.log("Hello");

//window.setTimeout(() => { basketpanel.setTopBarBasket($(".topbar__basket")); }, 1000);

  // set a timeout to make sure we show the page even with errors
  window.setTimeout(() => { 
    //console.log("add class init");
    document.documentElement.classList.add('init')
  }, 200);

  window.addEventListener('scroll', ev => onScroll(), util.supportsPassive() ? { passive: true } : false);
  onScroll();

  if (dompack.debugflags.addloginclass) {
    document.documentElement.classList.add('member-area');
  }

  //handleTitleH1();
  handleCookieConsent();
  setupMobileSearchContainer();
  setupSharePage();
  setupAnchorNavigation();

  // add class so we can fix floating images next to lists
  $('.wh-rtd__img--floatleft').each(function() {
    $(this).closest('p').addClass('hasfloatingimage');
  })

  // add 'scroll to top' action
  $('.js-scroll-to-top').click(evt => {
    evt.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, 600);
  });

  // add 'scroll to first content element' action
  $('.js-scroll-first-element').click(evt => {
    evt.preventDefault();
    const $elScrollTo = $('#contentwrapper-contents > *:first-child');
    if ($elScrollTo) {
      $('html, body').animate({ scrollTop: getScrollPositionForElement($elScrollTo) }, 600);
    }
  });

  //document.documentElement.classList.add('init');

  if (window.___isInStandaloneMode) {
    //console.log("display-mode: standalone");
/*
    if (typeof gtag !== "undefined") {
      gtag('set', 'user_properties', {
        browser_mode: "pwa"
      });
    }
*/
    document.documentElement.classList.add('pwa');
    //alert("in mode standalone (pwa)");

    addPWAFooter();
    addPWAHeader();
  }
  else {
    //console.log("display-mode: browser");
    //console.log("browser_mode: normal");
/*
    if (typeof gtag !== "undefined") {
      gtag('set', 'user_properties', {
        browser_mode: "normal"
      });
    }
*/
  }
  //console.log("pathname: ", window.location.pathname);

  // FIXME: jannes: for testing
  if (whintegration.config.dtapstage === "ddevelopment") {
    ___isInStandaloneMode = true;
    document.documentElement.classList.add('pwa');
    addPWAFooter();
    addPWAHeader();
  }

  if (document.documentElement.classList.contains('page-landing')) {
    // remove empty paragraphs
    $('#contentwrapper-contents > p.normal').each(function() {
      if ($(this).html() === '<br>') {
        $(this).remove();
      }
    });
  }

  if (document.documentElement.classList.contains('mystream')) {
    var initvar = getQueryVar("init");
    if (initvar === "open-attendance") {
      $("html.mystream aside .widgetblock.content .title").trigger('click');
      toggleWidgetBlock(".widgetblock.attendance");
      $('html, body').animate({ scrollTop: getScrollPositionForElement($(".widgetblock.attendance")) }, 600);
    }
  }

  if (wrdauth.getDefaultAuth().isLoggedIn())
  {
    //console.log("isloggedin");
    //let loginstatus = _getLoginStatus();
  };
  let loginstatus = _getLoginStatus();

/*
  $(document).on("visibilitychange", function(_e) {
    _e.preventDefault();
    _e.stopPropagation();
    //console.log("visibility change", document.visibilityState);
    if (visibilitysetup) {
      if (document.visibilityState === 'hidden') {
        //console.log("visibility change to hidden");

        if ($("html").hasClass("pwa-activity")) {
          alert("visibility change to hidden and has class pwa-activity");
        }
      }
    }
    visibilitysetup = true;
  });
*/

/*
  $(window).on("blur", function(_e) {
    if ($("html").hasClass("pwa-activity")) {
      if (didblur) {
        setTimeout(function() { didblur = false; }, 500);
        return;
      }
      didblur = true;
      _e.preventDefault();
      _e.stopPropagation();
      //console.log("blur event");
      alert("blur event");
    }
  });

  $(window).on("beforeunload", function(_e) {
    if ($("html").hasClass("pwa-activity")) {
      _e.preventDefault();
      _e.stopPropagation();
      //console.log("beforeunload event");
      alert("beforeunload event");
    }
  });
*/

  $(document).on("click", ".topbar__loginmenu .user.foldable, .topbar .topbar__logolink.haslinks", function(_e) {
    //console.log("show menu");
    _e.preventDefault();
    $(this).parent().toggleClass("open");
    $(this).parent().find(".menu").slideToggle();
  });

  $(document).on("click", ".widgetblock.content.foldable .title", function(e) {
    //console.log("widgetblock content title click");
    var content = $(this).closest(".widgetblock").find(".content");
    content.css("flex", "unset").slideToggle("slow", function() {content.css("flex", "0 0")});
    //$(this).closest(".widgetblock").find(".content").toggleClass("close").slideToggle("slow");
    $(this).closest(".widgetblock").find("span.open").toggleClass("nodisplay");
    $(this).closest(".widgetblock").find("span.close").toggleClass("nodisplay");
  });

  $(".clubcontactform.widgetblock.form #codeField").val("250520180000");
  $(document).on("click", ".clubcontactform.widgetblock.form .title", function(e) {
    //console.log("contactform widgetblock title click");
    $(this).closest(".widgetblock").find("#codeField").val("250520180000");
    $(this).closest(".widgetblock").find("> .content").slideToggle("slow").toggleClass("close");
    $(this).closest(".widgetblock").find("span.open").toggleClass("nodisplay");
    $(this).closest(".widgetblock").find("span.close").toggleClass("nodisplay");
  });

  $(document).on("click", "a.preventdefault", function(_e) {
    //console.log("preventDefault");
    _e.preventDefault();
  });

  $(document).on("click", ".filter.dropdown .filter-title", function(_e) {
    $(this).parent().find("ul").slideToggle();
  });

  $("form.standard .formfield.countwords textarea").each( function(_index, _item) {
    // set on pageload
    var textarea = $(this);
    setFieldWordCount(textarea);
  });

  $(document).on("keyup", "form.standard .formfield.countwords textarea", function(e) {
    var textarea = $(this);
    setFieldWordCount(textarea);
    //var textareacontent = $.trim(textarea.val());
    //var nrofwords = getCountWords(textareacontent);
    //textarea.parent().find(".counter").text( nrofwords + " woorden" );
  });

  $("form.standard").each( function(_index, _item) {
    var htmlform = $(this);
    if (htmlform.find(".formfield.files").length>0) {
      htmlform.bind("submit", function(_e) {
        if (!$("#fullscreenlayer").hasClass("hidden")) {
          _e.preventDefault();
          alert("Het formulier kan niet opgestuurd worden terwijl er nog iets geladen wordt. Probeer het later opnieuw.");
        }
      });
    }
  });

  $("form.showloaderonsubmit").on("submit", function(_e) {
    page.showLoader();
  });

  $(".formfield .filelist td.action a").on("click", function(_e) {
    _e.preventDefault();
    var deletelink = $(this);
    removeFormFileFromSession(deletelink, deletelink.data("name"));
  });

  $(".formfield.files").each( function(_index, _item) {
    var htmlform = $(_item).closest("form");
    var formfield = $(_item);
    var datasettings = formfield.data("settings");
    if (datasettings !== undefined) {
      //console.log("data-settings");
      //console.log(datasettings);
    }
    var dropzone = formfield.find(".dropzone");
    //console.log("dropzone");
    //console.log(dropzone);
    dropzone.on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
      e.preventDefault();
      e.stopPropagation();
    })
    .on('dragover dragenter', function() {
      dropzone.addClass('is-dragover');
    })
    .on('dragleave dragend drop', function() {
      dropzone.removeClass('is-dragover');
    })
    .on('drop', function(e) {
      storeFormFilesInSession(htmlform, formfield, e.originalEvent.dataTransfer.files, datasettings);
    });

    var inputfiles = formfield.find("input[type='file']");
    inputfiles.bind("change", function(_e) {
      //console.log({action: "change", item: _item, formfield: formfield});
      storeFormFilesInSession(htmlform, formfield, this.files, datasettings);
    });
  });

  if ($("#inputpersonfilter").length>0) {
    $(document).on("keyup", "#inputpersonfilter", function(_e) {
      var filtertext = $("#inputpersonfilter").val().toLowerCase();
      if (filtertext=="") {
        $("#smoelenboek .smoelenboekmember").removeClass("nodisplay");
      }
      else {
        var filteritems = $("#smoelenboek .smoelenboekmember").not("[data-person-name*='" + filtertext + "']");
        $("#smoelenboek .smoelenboekmember.nodisplay").not(filteritems).removeClass("nodisplay");
        filteritems.addClass("nodisplay");
      }
    });
    if ($("#inputpersonfilter").val().length > 0) {
      $("#inputpersonfilter").keyup();
    }
  }

  var videoplayerstocontrol = $(".w-fullvideo .c-video");
  if (videoplayerstocontrol && (videoplayerstocontrol.length>0) ) {
    var videoplayerscontroller = new VideoPlayersController(videoplayerstocontrol);
  }

  $(document).on("click", "html.pwa.myprofile a.header", function(_e) {
    //console.log("click a.header");
    _e.preventDefault();
    var memberdatablock = $(this).closest(".memberdata.block");
    memberdatablock.toggleClass("open");
    //processMaps();
  });
  var myprofilememberdatablocks = $("html.pwa.myprofile .memberdata.block");
  if (myprofilememberdatablocks.length>0) {
    $(myprofilememberdatablocks[0]).addClass("open");
  }


  $(window).on("beforeinstallprompt", function(_e) {
    //console.log("on 'beforeinstallprompt' event.");
    _e.preventDefault();
    deferredPWAPrompt = _e.originalEvent;
    var htmlelement = document.documentElement;
    htmlelement.classList.add('install-pwa');
  });

  $(document).on("click", "html.install-pwa .button-install-pwa", async (_e) => {
    //console.log("click install-pwa");
    _e.preventDefault();
    _e.stopPropagation();
    $("html").removeClass("install-pwa");
    if (deferredPWAPrompt !== undefined) {
      //console.log("deferredPWA prompt is not null");
      deferredPWAPrompt.prompt();
      const { outcome } = await deferredPWAPrompt.userChoice;
      //console.log(`User response to the install prompt: ${outcome}`);
      deferredPWAPrompt = null;
    }
    else {
      //console.log("deferredPWA prompt is null");
    }
  });

  $(document).on("click", "html.pwa #actionrefresh", function(_e) {
    //alert("refresh here");
    // keeping post data
    window.location.reload();
    // discarding post data
    //window.location.href = window.location.href;
  });

  let observer_options = {
    treshold: [0],
    rootMargin: '0px',
  }
  var observer = new IntersectionObserver(handleInViewItems, observer_options);
  let elements = document.querySelectorAll(".w-observe");
  for(let element of elements)
  {
    observer.observe(element);
  }

});

function handleInViewItems(_items, _observer)
{
  _items.forEach((_item) => {
    if (_item.isIntersecting) {
      //console.log("item getting into view", _item);
      _item.target.classList.add("w-inview");
    }
    else {
      //console.log("item getting out of view", _item);
      _item.target.classList.remove("w-inview");
    }
    let targetitem = {
      boundingClientRect: _item.boundingClientRect,
      intersectionRatio: _item.intersectionRatio,
      intersectionRect: _item.intersectionRect,
      isIntersecting: _item.isIntersecting,
      rootNounds: _item.rootBounds,
      target: _item.target,
      time: _item.time,
    }
    //console.log(targetitem);
  });
}

dompack.register('.splith1nav', node => new SplitH1Nav(node, { onPageChange: () => setupAnchorNavigation(true) }));

async function _getLoginStatus() {
  let loginstatus = await rpc.getLoginStatus() || {};
  //console.log("loginstatus: ", loginstatus);

  let gtag_user_properties = {};
  if (window.___isInStandaloneMode) {
    gtag_user_properties.browser_mode = "pwa";
  }
  else {
    gtag_user_properties.browser_mode = "normal";
  }

  if (loginstatus && loginstatus.ok && loginstatus.item)
  {
    //console.log(loginstatus.item.loginstate.displayname);
    $(".topbar__loginmenu .user .name a").text(loginstatus.item.displayname);
    $(".topbar__loginmenu .menu ul").html(loginstatus.item.mylinkshtml);
    $(".topbar__loginmenu").removeClass("nodisplay");

    if (loginstatus.item.logolinkshtml)
    {
      $(".topbar__logocontainer .menu").html('<ul>' + loginstatus.item.logolinkshtml + '</ul>');
      $(".topbar .topbar__logolink").addClass("haslinks");
    }

    $(".mobilemenu__navlistitem--user > a").text(loginstatus.item.displayname);
    $(".mobilemenu__navlistitem--user ul").html(loginstatus.item.mylinkshtml);
    $(".mobilemenu__navlistitem--user").removeClass("nodisplay");

    if (loginstatus.item.islogin_rotary)
    {
      //console.log("login rotary");
      $("html").addClass("login-rotary");
    }
    if (loginstatus.item.islogin_rotaract)
    {
      //console.log("login rotaract");
      $("html").addClass("login-rotaract");
    }

    if ($(".districtcommittees tr.person").length>0) {
      $(document).on("click", ".districtcommittees tr.person", function(_e) {
        const link = "/leden/app/person/?ed=" + $(this).data("ed");
        window.location.href = link;
      });
    }

    if ($("#clubmembers .memberinfo").length>0) {
      $(document).on("click", "#clubmembers .memberinfo", function(_e) {
        const link = "/leden/app/person/?ed=" + $(this).data("ed");
        window.location.href = link;
      });
    }

    if (loginstatus.item.isexternallogin)
    {
      $("html").addClass("external--isloggedin");
    }
    else {
      var htmlcheckboxes = "";
      htmlcheckboxes += '<input type="checkbox" id="chkpersons" name="chkpersons" checked="checked" class="hidden" />';
      htmlcheckboxes += '<input type="checkbox" id="chkclubs" name="chkclubs" checked="checked" class="hidden" />';
      htmlcheckboxes += '<input type="checkbox" id="chkhelpdesk" name="chkhelpdesk" checked="checked" class="hidden" />';
      htmlcheckboxes += '<input type="checkbox" id="chkwebsite" name="chkwebsite" checked="checked" class="hidden" />';
      //$("form.topbar__searchform").attr("action", "/leden/app/zoeken/");
      $("form.topbar__searchform").attr("action", "/leden/app/search.html");
      $("form.topbar__searchform").append(htmlcheckboxes);
    }

    if (loginstatus.item.clubname)
    {
      $(".pwa-header__logocontainer.logo-and-name p").text(loginstatus.item.clubname);
    }
    //console.log("mycluburl", loginstatus.item.pwamycluburl);
    if (loginstatus.item.pwamycluburl)
    {
      $(".nav-pwa .navitem.myclub a").attr("href", loginstatus.item.pwamycluburl);
    }

    if (window.top !== window.self) {
      $("html").addClass("framed");
      //console.log("framed");
    }

    let contextdata = _getContextData();

    let gtagclubname = loginstatus.item.clubname;
    if (loginstatus.item.islogin_rotary) {
      gtagclubname += " (Rotary)";
    }
    else if (loginstatus.item.islogin_rotaract) {
      gtagclubname += " (Rotaract)";
    }
    //console.log(gtagclubname);
    if (typeof gtagclubname !== "undefined") {
      gtag_user_properties.club_name = gtagclubname;
    }
  }
  if (loginstatus && loginstatus.basketdata )
  {
    setTimeout(function() {
    //var addhtml = '<a class="topbar__basket" href="#"><div class="topbar__basket__icon-badge"><span class="topbar__basket__icon fa fa-shopping-basket"></span><span class="topbar__basket__badge nodisplay"></span></div></a>';
    //$(".topbar__container").append(addhtml);
    if (loginstatus.basketdata.nrofitems>0) {
      $(".topbar__basket__badge").text(loginstatus.basketdata.nrofitems);
      $(".topbar__basket__badge").removeClass("nodisplay");
      $("html").addClass("hasbasket");
    }
    if ( (loginstatus.basketdata) && (loginstatus.basketdata.baskets) && (loginstatus.basketdata.baskets.length>0) ) {
      $("a.topbar__basket").attr("href", loginstatus.basketdata.baskets[0].basketurl);
    }
    }, 200);
      
  }

  //console.log("gtag_up", gtag_user_properties);
  if (typeof gtag !== "undefined") {
    gtag('set', 'user_properties', gtag_user_properties);
  }
};

async function _getContextData() {
  var publisherdata = $("button.open-in-publisher").data("publisher");
  if (publisherdata)
  {
    let contextdata = await rpc.getContextData({ 'fsobjectid': publisherdata.id }) || {};
    //console.log("contextdata: ", contextdata);
    if (contextdata && contextdata.ok && contextdata.item)
    {
      if (contextdata.item.hasfullaccessonfsobject) {
        //console.log("hasfullaccessonfsobject");
        if ($("html").hasClass("pwa")) {
          $("button.open-in-publisher").removeClass("show");
        } else {
          $("button.open-in-publisher").addClass("show");
        }
        $(document).on("click", "button.open-in-publisher", function(_e) {
          //console.log("open-in-publisher");
          //var publisherdata = $(this).data("publisher");
          //var publisherdata = $.parseJSON($(this).data("publisher"));
          //console.log(publisherdata);
          var url = "/access/portal/?app=publisher(" + publisherdata.id + ")";
          //console.log(url);
          window.open(url, "_blank");
        });
      }
      if (contextdata.item.hasbrowseonany) {
        $(".widgetblock.publisher-any").css({'display':'flex'});
      }
    }
  }
}

function toggleWidgetBlock(_selector)
{
  var widgetblock = $(_selector);
  var content = widgetblock.find(".content");
  content.css("flex", "unset").slideToggle("slow", function() {content.css("flex", "0 0")});
  //$(this).closest(".widgetblock").find(".content").toggleClass("close").slideToggle("slow");
  widgetblock.find("span.open").toggleClass("nodisplay");
  widgetblock.find("span.close").toggleClass("nodisplay");
}

function calcTextAmount(_nrofchars, _config)
{
  if (_nrofchars === 0) {
    return 0;
  }
  var result = _config.amountbase;
  var nrofcharsremaining = _nrofchars;
  if (_nrofchars <= _config.nrofcharsbase) {
    return result;
  }
  else {
    nrofcharsremaining = nrofcharsremaining - _config.nrofcharsbase;
    result = result + ( Math.ceil(nrofcharsremaining/_config.nrofcharsextra) * _config.amountextra);
  }
  return result;
}

function getMoney(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2}); //.toFixed(decimals);
}

function getCountWords(_text)
{
  return _text.split(/\s+/).length;
}

function setFieldWordCount(_formfield)
{
  var textareacontent = $.trim(_formfield.val());
  if (textareacontent.length>0) {
    var nrofwords = getCountWords(textareacontent);
    var nrofwordstext = nrofwords + " woord";
    if (nrofwords>1) {
      nrofwordstext += "en";
    }
    _formfield.parent().find(".counter").text( nrofwordstext );
  }
  else {
    _formfield.parent().find(".counter").text( "Nog geen woorden ingevoerd." );
  }
}

function removeFormFileFromSession(_deletelink, _name)
{
  page.showLoader();
  //console.log({action:"delete", name: _name});
  $.ajax({
    url: "/data.shtml",
    type: "POST",
    data: {
      action: "removeFormFileFromSession"
    , name: _name
    },
    //dataType: 'json',
    //cache: false,
    //contentType: false,
    //processData: false,
    complete: function() {
      //_form.removeClass('is-uploading');
    },
    success: function(_data) {
      setTimeout(function() {
        page.hideLoader();
      }, 500);
      //console.log({successdata: _data});
      if (_data.ok) {
        _deletelink.closest("tr").remove();
      }
      else {
        if (_data.status === -1) {
          // not logged in
          alert("Uw login sessie is verlopen, ververs de pagina (F5), en log in.");
        }
      }
    },
    error: function() {
      // Log the error, show an alert, whatever works for you
      setTimeout(function() {
        page.hideLoader();
      }, 1000);
      alert("Er is iets fout gegaan bij het verwijderen van het bestand / afbeelding. Probeer het (later) opnieuw. En/of ververs ook uw pagina (F5).");
    }
  });
}

function storeFormFilesInSession(_form, _formfield, _files, _settings)
{
  //console.log({form: _form, formfield: _formfield, files:_files});

  var maxfilesize = 10 * 1024 * 1024;
  var acceptedmimetypes = [ "image/jpg" ];
  if (_settings) {
    maxfilesize = _settings.maxfilesize || maxfilesize;
    acceptedmimetypes = _settings.acceptedmimetypes || acceptedmimetypes;
  } 
  //console.log({maxfilesize:maxfilesize});
  //var ajaxData = new FormData(_form.get(0));
  var ajaxData = new FormData();
    
  if (_files) {
    var i = _formfield.find(".filelist table tr.fileitem").length+1;
    $.each( _files, function(_i, _file) {
      var fileerrors = "";
      var fileinputname = _formfield.find("input").attr('name') + "_" + i;
      //console.log({item: _file.name, i: _i, fileinputname: fileinputname, file: _file});
      i +=1;
      if (_file.size>maxfilesize) {
        fileerrors += "Bestand te groot, maximaal " + maxfilesize + " bytes<br />";
      }
      if (acceptedmimetypes.indexOf(_file.type) === -1) {
        fileerrors += "Type van bestand niet ondersteund.<br />"; // + " (" + _file.type + ")<br />";
      }
      if (fileerrors === "") {
        ajaxData.append( fileinputname, _file );
      }
      //console.log({ajaxData: ajaxData});
      /*
      var newrow = '<tr class="fileitem ' + fileinputname + '">';
      newrow += '<td class="preview"><img src="/resources/img/preview-no-image.png" width="64" height="64" border="0" alt="" /></td>';
      newrow += '<td class="status"><img src="/resources/img/dummy.gif" width="16" height="16" border="0" alt="" /></td>';
      newrow += '<td class="action"></td>';
      newrow += '<td class="file"><div class="filename">' + _file.name + '</div>';
      newrow += '<div class="error' + (fileerrors ? (" " + fileerrors) : "") + '">' + ( fileerrors || "" ) + '</div>';
      newrow += '</td>';
      newrow += '</tr>';

      var filelisttable = _formfield.find(".filelist table");
      //console.log(filelisttable);
      filelisttable.append(newrow);
      */

      var elmnewrow = document.createElement("tr");
      elmnewrow.className += "fileitem " + fileinputname;
      var newrowcontent = "";
      newrowcontent += '<td class="preview"><img src="/resources/img/preview-no-image.png" width="64" height="64" border="0" alt="" /></td>';
      newrowcontent += '<td class="status"><span' + (fileerrors ? (' class="error"') : "") + '>&nbsp;</span></td>';
      newrowcontent += '<td class="action"></td>';
      newrowcontent += '<td class="file"><div class="filename">' + _file.name + '</div>';
      newrowcontent += '<div class="error' + (fileerrors ? ("") : " nocss") + '">' + ( fileerrors || "" ) + '</div>';
      newrowcontent += '</td>';
      elmnewrow.innerHTML = newrowcontent;

      var elmdeletefileitem = document.createElement("a");
      elmdeletefileitem.href = "#";
      elmdeletefileitem.textContent = "Verwijderen";
      elmdeletefileitem.title = "Bestand verwijderen";
      elmdeletefileitem.setAttribute("data-name", fileinputname);
      elmdeletefileitem.addEventListener("click", function(_e) {
        _e.preventDefault();
        removeFormFileFromSession($(this), fileinputname);
      });
      //elmnewrow.querySelector("td.action").appendChild(elmdeletefileitem);
      $(elmnewrow).find("td.action").append(elmdeletefileitem);

      _formfield.find(".filelist table").append(elmnewrow);

    });
  }
  //console.log("ajaxData");
  //console.log(ajaxData);

  page.showLoader();
  $.ajax({
    url: "/data.shtml?action=storeFormFilesInSession",
    type: "POST",
    data: ajaxData,
    dataType: 'json', 
    cache: false,
    contentType: false,
    processData: false,
    complete: function() {
      _form.removeClass('is-uploading');
    },
    success: function(_data) {
      setTimeout(function() {
        page.hideLoader();
      }, 1000); 
      if (_data.ok)
      {
        //if (!data.success) $errorMsg.text(data.error);
        //console.log({successdata: _data});
        $.each(_data.items, function(_index, _item) {
          setTimeout(function() {
          //console.log(_item);
          var sizestr = "";
          if (_item.scanblob.width) {
            sizestr = " (b: " + _item.scanblob.width + " px, h: " + _item.scanblob.height + " px)";
            var elfilename = _formfield.find(".filelist table tr." + _item.name + " .filename");
            elfilename.text(elfilename.text() + sizestr);
          }

          var formfieldstatusclass = "ok";

          if (_item.antivirusscan) 
          {
            if (!(_item.antivirusscan.success)) {
              if ( (_item.antivirusscan.errorcode === "allscannersfailed") || (_item.antivirusscan.errorcode === "noscannersavailable") ) {
              }
              else {
                //setFormError(form, "image", "Er lijkt een probleem met dit bestand te zijn (" || antivirusscan.detail || ") en het kan daardoor niet geupload worden.");
                var errortext = "Er lijkt een probleem met dit bestand te zijn (" + _item.antivirusscan.detail + ") en het kan daardoor niet geupload worden."
                var elfileerror = _formfield.find(".filelist table tr." + _item.name + " .file .error");
                elfileerror.text(elfileerror.text() + errortext );
                formfieldstatusclass = "error";
              }
            }
          }

          _formfield.find(".filelist table tr." + _item.name + " td.status span").addClass(formfieldstatusclass);
          if (_item.thumbnail) {
            _formfield.find(".filelist table tr." + _item.name + " td.preview img").attr("src", _item.previewdataurl).attr("width", _item.thumbnail.width).attr("height", _item.thumbnail.height);
          }

          console.log(_item);
          }, 500);

        });
      }
      else {
        if (_data.status === -1) {
          // not logged in
          alert("Uw login sessie is verlopen, ververs de pagina (F5), en log in.");
        }
        else {
          // fixme: jm; handle errors here
        }
      }
    },
    error: function() {
      // Log the error, show an alert, whatever works for you
      setTimeout(function() {
        page.hideLoader();
      }, 1000);
      alert("Er is iets fout gegaan bij het uploaden van de bestanden / afbeeldingen. Probeer het (later) opnieuw. En/of ververs ook uw pagina (F5).");
    }
  });
}

function getQueryVar(_varname)
{
  var querystring = window.location.search.substring(1);
  var queryvars = querystring.split("&");
  for(var i=0; i<queryvars.length; i++) {
    var queryvar = queryvars[i].split("=");
    if (queryvar[0] == _varname) {
      return queryvar[1];
    }
  }
  return "";
}

function handleCookieConsent() {
  if (typeof cnsnt !== "undefined") {
    if (hascnsnt) { // status = "accepted"
      $("html").addClass("cnsnt");
    }
    else {
      $("html").addClass("nocnsnt");
    }
  }
  else {
    if ($("html").hasClass("rotarynl")) {
      $("html").addClass("chkcnsnt");
    }
    else {
      return;
    }
  }

  var l = $("html.cnsnt,html.nocnsnt,html.chkcnsnt").length;
  //console.log("l: ", l);
  if (getQueryVar("action") === "show-consent")
  {
    var text = "<p>Uw huidige status voor de marketing cookies is: ";
    var datetext;
    var buttons = "";
    if (cnsnt) {
      if (hascnsnt) { // status = "accepted"
        text += "geaccepteerd";
        buttons += '<a href="" class="button nocnsnt">Weigeren</a> ';
      }
      else {
        text += "geweigerd";
        buttons += '<a href="" class="button cnsnt">Accepteren</a> ';
      }
      buttons += '<a href="" class="button deletecnsnt">Verwijderen</a>';
      datetext = ' (' + new Date(cnsnt['marketingautomation'].datetime).toLocaleString('nl-NL') + ')';
      text += datetext + '. ';
    }
    else {
      text += "nog geen keuze gemaakt.";
    }

    var readmore = '<span class="readmore"><a href="/contact/" class="readmore">Lees meer</a>.</span>';
    $("header").prepend('<div class="cookieconsent show" role="dialog"><p>' + text + ' ' + readmore + '<span class="buttons">' + buttons + '</span></p>');
    $("html").addClass("shwcnsnt");
  }

  $("header .cookieconsent .button.cnsnt").click( function ( _e ) {
    _e.preventDefault();
    var ck = { 'marketingautomation': { 'status': "accepted", 'datetime': thisdate.getTime()} }
    //console.log(JSON.stringify(ck));
    updateCookie("ckcnsnt", JSON.stringify(ck), 90);
    $("header .cookieconsent").html("<p>De pagina wordt binnen enkele ogenblikken opnieuw geladen om uw keuze te verwerken.</p>");
    setTimeout( function() {
      location = location;
    }, 2000);
  });

  $("header .cookieconsent .button.nocnsnt").click( function ( _e ) {
    _e.preventDefault();
    var ck = { 'marketingautomation': { 'status': "rejected", 'datetime': thisdate.getTime()} }
    //console.log(JSON.stringify(ck));
    updateCookie("ckcnsnt", JSON.stringify(ck), 90);
    deleteCookie("__ss_referrer");
    deleteCookie("__ss_tk");
    deleteCookie("__ss");
    deleteCookie("koitk");
    $("header .cookieconsent").html("<p>De pagina wordt binnen enkele ogenblikken opnieuw geladen om uw keuze te verwerken.</p>");
    setTimeout( function() {
      location = location;
    }, 2000);
  });

  $("header .cookieconsent .button.deletecnsnt").click( function ( _e ) {
    _e.preventDefault();
    //console.log("delete cookie");
    deleteCookie("ckcnsnt");
    deleteCookie("__ss_referrer");
    deleteCookie("__ss_tk");
    deleteCookie("__ss");
    deleteCookie("koitk");
    $("header .cookieconsent").html("<p>De pagina wordt binnen enkele ogenblikken opnieuw geladen om uw keuze te verwerken.</p>");
    setTimeout( function() {
      location = location;
    }, 2000);
  });
}

function handleTitleH1()
{
  var $pageh1 = $('main.contentwrapper__col--content > h1');
  if ($pageh1.length == 1) {
    if ($("html").hasClass("splith1"))
    {
      var $visibleh1 = $(".contentwrapper__contents h1.wh-heading1:not([hidden])");
      if ($visibleh1.length>0) {
        $pageh1.addClass("nodisplay");
      }
      else {
        $pageh1.removeClass("nodisplay");
      }
    }
    else {
      var contentselements = $(".contentwrapper__contents").children();
      if (contentselements.length>0) {
        if ((contentselements[0].tagName == "H1") && (contentselements[0].classList.contains("wh-heading1")))
        {
          console.log("starts with h1");
          $pageh1.addClass("nodisplay");
        }
      }
    }
  }
}
