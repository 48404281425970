import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import '@mod-system/js/wh/integration';
import $ from 'jquery';
import * as page from '../../shared/js/page';
import * as wrdauth from '@mod-wrd/js/auth';
import * as rpc from '../../data.rpc.json';

import './webshop.scss';


class WebShopBasketPanel__ {
  constructor(_$topbar__basket) {
    let self = this;
    this.inited = false;
    this.log("Setting up WebShopBasketPanel");

  }

  log(_text)
  {
    console.log(_text);
  }

  setupTopBarBasket()
  {
    var addhtml = '<a class="topbar__basket" href="#"><div class="topbar__basket__icon-badge"><span class="topbar__basket__icon fa fa-shopping-basket"></span><span class="topbar__basket__badge nodisplay"></span></div></a>';
    $(".topbar__container").append(addhtml);
  }

  setTopBarBasket(_$topbar__basket)
  {
    //console.log("setTopBarBasket");
    this.$topbar__basket = _$topbar__basket;
    //console.log("setTopBarBasket basket", this.$topbar__basket);
    this.$topbar__basket__badge = this.$topbar__basket.find(".topbar__basket__badge");
    //console.log("setTopBarBasket badge", this.$topbar__basket__badge);
  }

  updateTopBarBasket(_newvalue)
  {
    //console.log("updateTopBarBasket");
    if (this.$topbar__basket__badge.length>0) {
      //console.log("updateTopBarBasket: ", _newvalue, this.$topbar__basket__badge);
      this.$topbar__basket__badge.text(_newvalue);
    }
  }

  requiresetup()
  {
    //console.log("require setup");
    let addhtml = "";
    addhtml += '<div class="webshop__basket__fullscreen">';
    addhtml += '  <div class="webshop__basket__container">';
    addhtml += '    <span class="webshop__basket__close"><i class="fas fa-window-close"></i></span>';
    addhtml += '    <div class="webshop__basket__header">';
    addhtml += '      <h2 class="webshop__basket__title">Winkelmandje</h2>';
    addhtml += '    </div>';
    addhtml += '    <div class="webshop__basket__body">';
    addhtml += '      <div class="webshop__basket__intro">';
    addhtml += '      </div>';
    addhtml += '      <div class="webshop__basket__content">';
    addhtml += '        <h3>inhoud hier</h3>';
    addhtml += '      </div>';
    addhtml += '      <div class="webshop__basket__loader__container">';
    addhtml += '        <div class="webshop__basket__loader"></div>';
    addhtml += '      </div>';
    addhtml += '    </div>';
    addhtml += '  </div>';
    addhtml += '</div>';
    if ($(".webshop__basket__fullscreen").length==0) {
      $("body").append(addhtml);
    }
    else {
      $(".webshop__basket__fullscreen").removeClass("display--none");
    }
  }

  setTitle(_value)
  {
    $(".webshop__basket__container .webshop__basket__title").text(_value);
  }

  setIntroHTML(_value)
  {
    $(".webshop__basket__container .webshop__basket__intro").html(_value);
  }

  setContentHTML(_value)
  {
    $(".webshop__basket__container .webshop__basket__content").html(_value);
  }

  show(_refresh) {
    let self = this;
    const refresh = _refresh || false;
    setTimeout(() => { $("html").addClass("showbasket"); }, 100);
    this.requiresetup();
    if (refresh) {
      this.refresh();
    }
    $(document).on("click", ".webshop__basket-simple", function(_e) {
      _e.preventDefault();
      _e.stopPropagation();
      let index = $(this).data("index");
      //$currenttarget.addClass("nodisplay");
      //console.log(_e);
      //console.log("clicked: ", index);
      self.showBasket(index);
    });
  }

  close() {
    $("html").removeClass("showbasket");
    setTimeout(() => { $(".webshop__basket__fullscreen").addClass("display--none"); }, 300);
    this.setTitle("Winkelmandje");
    this.setContentHTML("");
  }

  storeBaskets(_baskets)
  {
    let self = this;
    this.baskets = _baskets;
    this.basketsa = [];
    this.baskets.forEach(function(_currentvalue, _index, _array) {
      if (typeof (self.basketsa[_currentvalue.basketurl]) === "undefined") {
        //console.log("additem", _currentvalue.basketurl);
        self.basketsa[_currentvalue.basketurl] = _index;
      }
    });
  }

  getBasketIndexByBasketURL(_basketurl)
  {
    if (this.baskets.length==0) {
      return -1;
    }
    return this.basketsa[_basketurl];
  }

  getBasketByBasketURL(_basketurl)
  {
    if (this.baskets.length==0) {
      return {};
    }
    const basketindex = this.basketsa[_basketurl];
    return this.baskets[basketindex];
  }

  showEmptyBasket(_index)
  {
    let html = "";
    html += '<div class="webshop__basket__items">';
    html += '  <h3 class="webshop__basket__items__title">Items</h3>';
    html += '  <p>Geen items gevonden in dit winkelmandje.</p>';
    html += '</div>';
    html += '<div class="webshop__basket__bottom">';
    html += '  <div class="webshop__basket__bottom__buttons">';
    html += '    <a href="#" title="Verder winkelen" class="webshop__basket__block webshop__basket__continue button gold-royalblue"><span>Verder winkelen</span></a>';
    html += '  </div>';
    html += '</div>';
    this.setContentHTML(html);
  }

  showBasket(_index)
  {
    let self = this;
    this.showLoader();
    this.setTitle("Winkelmandje");
    const basket = this.baskets[_index]; 
    let html = "";
    if (typeof basket === "undefined") {
      this.showEmptyBasket();
    }
    else {
      html += '<div class="webshop__basket__intro__content">';
      html += '  <div class="webshop__basket__intro__item">';
      html += '    <div class="webshop__basket__toplabel">WebShop</div>';
      html += '    <div class="webshop__basket__text">' + basket.title + '</div>';
      html += '  </div>';
      html += '  <div class="webshop__basket__intro__item">';
      html += '    <div class="webshop__basket__toplabel">Organisatie</div>';
      html += '    <div class="webshop__basket__text">' + basket.organization + '</div>';
      html += '  </div>';
      html += '</div>';
      this.setIntroHTML(html);
      this.refreshBasketData(_index);
    }
  }

  addArticle(_edata, _basketurl)
  {
    let self = this;
    let data = {
      edata: _edata
    , includeitems: true
    , url: _basketurl
    };
    this.showLoader();
    rpc.addArticleToBasket(data).then((_response) => {
//console.log("addArticle data: ", data);
//console.log("addArticle response:", _response);
//console.log("addArticle basketurl:", _basketurl);
      self.storeBaskets(_response.basketdata.baskets)
      const basketindex = self.getBasketIndexByBasketURL(_basketurl);
      self.refreshBasketData(basketindex);
      setTimeout(() => {self.hideLoader();}, 500);
    });
  }

  removeArticle(_edata, _basketurl, _removeall)
  {
    let self = this;
    let data = {
      edata: _edata
    , includeitems: true
    , removeall: _removeall || false
    };
    this.showLoader();
    rpc.removeArticleFromBasket(data).then((_response) => {
      self.storeBaskets(_response.basketdata.baskets)
      const basketindex = self.getBasketIndexByBasketURL(_basketurl);
      self.refreshBasketData(basketindex);
      setTimeout(() => {self.hideLoader();}, 500);
    });
  }

  refreshBasketData(_index)
  {
    let self = this;
    const basket = this.baskets[_index] || { items: [] };;

    $(document).off("click", ".webshop__basket__item__action--add1");
    $(document).on("click", ".webshop__basket__item__action--add1", function(_e) {
      _e.preventDefault();
      _e.stopPropagation();
      const $item = $(this).closest(".webshop__basket__item");
      self.addArticle($item.data("ed"), basket.basketurl);
    });

/*
    $(document).off("click", ".webshop__basket__container");
    $(document).on("click", ".webshop__basket__container", function(_e) {
      //if (_e.target === _e.currentTarget) {
      //}
      //_e.preventDefault();
      _e.stopPropagation();
    });
*/

    $(document).off("click", ".webshop__basket__item__action--remove1");
    $(document).on("click", ".webshop__basket__item__action--remove1", function(_e) {
      _e.preventDefault();
      _e.stopPropagation();
      const $item = $(this).closest(".webshop__basket__item");
      self.removeArticle($item.data("ed"), basket.basketurl);
    });

    $(document).off("click", ".webshop__basket__item__action--remove");
    $(document).on("click", ".webshop__basket__item__action--remove", function(_e) {
      _e.preventDefault();
      _e.stopPropagation();
      const $item = $(this).closest(".webshop__basket__item");
      self.removeArticle($item.data("ed"), basket.basketurl, true);
    });

    let html = "";
    html += '<div class="webshop__basket__items">';
    html += '  <h3 class="webshop__basket__items__title">Items</h3>';
    if (basket.items.length==0) {
      html += '<p>Geen items gevonden in dit winkelmandje.</p>';
    }
    else {
      this.updateTopBarBasket(basket.nrofitems);
      basket.items.forEach(function(_currentvalue, _index, _array) {
        html += '<div class="webshop__basket__item" data-ed="' + _currentvalue.edata + '">';
        html += '  <div class="webshop__basket__item__image">';
        if (_currentvalue.image_wrapped) {
          html += '  <img src="' + _currentvalue.image_wrapped.link + '" class="fa fa-shopping-basket" style="object-fit:cover;object-position:';
          if (_currentvalue.image_wrapped.refpoint_backgroundposition) {
            html += _currentvalue.image_wrapped.refpoint_backgroundposition;
          }
          else {
            html += 'center center';
          }
          html += '" />';
        }
        html += '  </div>';
        html += '  <div class="webshop__basket__item__info">';
        html += '    <h3 class="webshop__basket__item__title">' + _currentvalue.current_wrd_title + '</h3>';
        html += '    <span class="webshop__basket__item__price">' + basket.valuta + ' ' + _currentvalue.fmt_current_price + '</span>';
        html += '  </div>';
        html += '  <div class="webshop__basket__item__actions">';
        html += '    <span class="webshop__basket__item__action webshop__basket__item__action--remove"><i class="fa fa-trash-alt"></i></span>';
        html += '    <span class="webshop__basket__item__action webshop__basket__item__action--remove1"><i class="fa fa-minus"></i></span>';
        html += '    <span class="webshop__basket__item__nrofitems webshop__basket__item__action--updatenrofitems">' + _currentvalue.nrofitems + '</span>';
        html += '    <span class="webshop__basket__item__action webshop__basket__item__action--add1"><i class="fa fa-plus"></i></span>';
        html += '  </div>';
        html += '</div>';
      });
      html += '  <div class="webshop__basket__totalamount">';
      html += '    <h3>Totaalbedrag: ' + basket.valuta + ' ' + basket.fmttotalamount + '</h3>';
      html += '    <p class="webshop__basket__exemptvat"><i>(vrijgesteld van B.T.W.)</i></p>';
      html += '  </div>';
    }
    html += '</div>';
    html += '<div class="webshop__basket__bottom">';
    html += '  <div class="webshop__basket__bottom__buttons">';
    if (basket.iscurrentsite ) {
      html += '<a href="' + basket.basketurl + '" title="Bestellen" class="webshop__basket__block button gold-royalblue"><span>Bestellen</span></a>';
    }
    else {
      html += '<a href="' + basket.webshopurl + '" title="Naar webshop" class="webshop__basket__block button gold-royalblue"><span>Naar webshop</span></a>';
    }
    html += '<a href="#" title="Verder winkelen" class="webshop__basket__block webshop__basket__continue button gold-royalblue"><span>Verder winkelen</span></a>';
    html += '  </div>';
    html += '</div>';
    this.setContentHTML(html);


    //html += '<div class="webshop__basket">';
    //html += '</div>';
    //this.setContentHTML(html);
    setTimeout(() => {this.hideLoader();}, 500);
  }

  refresh()
  {
    let self = this;
    //console.log("basket refresh");
    this.showLoader();
    rpc.getBasketData({ 'includeitems':true, 'url':window.location.href }).then( (_response) => {
      //console.log("response:", _response);
      self.storeBaskets(_response.basketdata.baskets)
      if (_response.basketdata.baskets.length==0) {
        //self.showEmptyBasket();
        self.showBasket(-1);
      }
      else if (_response.basketdata.baskets.length==1) {
        //self.showEmptyBasket();
        self.showBasket(0);
      }
      else if (_response.basketdata.baskets.length>1) {
        this.setTitle("Winkelmandjes");
        this.setIntroHTML('<p>Je hebt meerdere winkelmandjes op rotary.nl:</p>');
        let html = "";
        _response.basketdata.baskets.forEach(function(_currentvalue, _index, _array) {
          html += '<div class="webshop__basket-simple' + (_currentvalue.iscurrentsite ? ' currentsite' : '') + '" data-index="'+ _index + '">';
          html += '  <div class="webshop__basket-simple__icon">';
          html += '    <i class="fa fa-shopping-basket"></i>';
          html += '  </div>';
          html += '  <div class="webshop__basket-simple__name">';
          html += '    <div class="webshop__basket__toplabel">Webshop' + (_currentvalue.iscurrentsite ? ' (huidige site)' : '') + '</div>';
          html += '    <div class="webshop__basket-simple__name__shop">' + _currentvalue.title + '</div>';
          html += '    <div class="webshop__basket__toplabel">Organisatie</div>';
          html += '    <div class="webshop__basket-simple__name__org">' + _currentvalue.organization + '</div>';
          html += '  </div>';
          html += '  <div class="webshop__basket-simple__content">';
          html += '    <div class="webshop__basket__toplabel">Items</div>';
          html += '    <div class="webshop__basket-simple__content__nrofitems">' + _currentvalue.nrofitems + '</div>';
          html += '    <div class="webshop__basket__toplabel">Bedrag</div>';
          html += '    <div class="webshop__basket-simple__content__mount">' + _currentvalue.valuta + ' ' + _currentvalue.fmttotalamount + '</div>';
          html += '  </div>';
          html += '</div>';
          self.setContentHTML(html);
        });
      }
      setTimeout(() => {self.hideLoader();}, 500);
    });
  }

  showLoader(_addcolorclass)
  {
/*
    const addcolorclass = _addcolorclass || "";
    if (addcolorclass.length>0) {
      $(".webshop__basket__loader__container").addClass(_addcolorclass);
    }
*/
    $(".webshop__basket__loader__container").removeClass("display--none");
  }

  hideLoader()
  {
    $(".webshop__basket__loader__container").addClass("display--none");
  }
}

let basketpanel = new WebShopBasketPanel__();
export default basketpanel;


dompack.onDomReady(() => {

  basketpanel.setupTopBarBasket();

  let $topbar__basket = $(".topbar__basket");
  //console.log("nu: ", $topbar__basket);
  basketpanel.setTopBarBasket($(".topbar__basket"));
  basketpanel.updateTopBarBasket("3");

  if ($("html").hasClass("webshop"))
  {
    $(document).on("click", "a.addtobasket", function(_e) {
      _e.preventDefault();
      //alert("add to basket");
      let $article = $(this).closest(".webshop__article");
      let $data_ed = $article.data("ed");
      //console.log($data_ed);

      let data = {
        edata: $data_ed
      }
      basketpanel.show();
      const basketurl = $(".topbar__basket").attr("href");
      basketpanel.addArticle($data_ed, basketurl);
      return;
      _addArticleToBasket(data);
    });

    $(document).on("click", "a.removefrombasket", function(_e) {
      _e.preventDefault();
      //alert("remove to basket");
      let $article = $(this).closest(".webshop__article");
      let $data_ed = $article.data("ed");
      //console.log($data_ed);

      let data = {
        edata: $data_ed
      }
      _removeArticleFromBasket(data);
    });

    $(document).on("click", "a.topbar__basket", function(_e) {
      _e.preventDefault();
      basketpanel.show(true);
      return;
/*
      basketpanel.log("click here");
      basketpanel.updateTopBarBasket("9");
      basketpanel.refresh();
      return;
      let addhtml = "";
      addhtml += '<div class="webshop__basket__fullscreen">';
      addhtml += '  <div class="webshop__basket__container">';
      addhtml += '    <span class="webshop__basket__close"><i class="fas fa-window-close"></i></span>';
      addhtml += '    <div class="webshop__basket__header">';
      addhtml += '      <h2 class="webshop__basket__title">Winkelmandje</h2>';
      addhtml += '    </div>';
      addhtml += '    <div class="webshop__basket__intro">';
      addhtml += '    </div>';
      addhtml += '    <div class="webshop__basket__content">';
      addhtml += '      <h3>inhoud hier</h3>';
      addhtml += '    </div>';
      addhtml += '    <div class="loader"></div>';
      addhtml += '  </div>';
      addhtml += '</div>';
      if ($(".webshop__basket__fullscreen").length==0) {
        $("body").append(addhtml);
      }
      else {
        $(".webshop__basket__fullscreen").removeClass("display--none");
      }
      //let response = rpc.getBasketData() || {};
      rpc.getBasketData().then( (_response) => {
        //console.log("response:", _response);
      });
*/
    });

    $(document).on("click", ".webshop__basket__fullscreen, .webshop__basket__close, .webshop__basket__continue, .webshop__basket__continue *", function(_e) {
      if (_e.target == _e.currentTarget) {
        _e.stopPropagation();
        basketpanel.close();
      }
      //$(".webshop__basket__fullscreen").addClass("display--none");
      //$(".webshop__basket__content").html("");
      //_e.preventDefault();
      //_e.stopPropagation();
      //basketpanel.close();
    });

/*
    let $webshopdata = $("#webshopdata");
//console.log("$webshopdata", $webshopdata);
    if ($webshopdata.length>0) {
      let basketlink = $webshopdata.data("basketlink");
      setTimeout(function() {
      let $topbarbasket =  $("a.topbar__basket");
//console.log("topbar_basket", $topbarbasket.attr("href"));
      if (basketlink) {
//console.log("basketlink", basketlink);
        $("a.topbar__basket").attr("href", basketlink);
      }
      }, 300);
    }
*/
  }
});

async function _addArticleToBasket(_data) {
  let response = await rpc.addArticleToBasket(_data) || {};
  //console.log("addarticletobasket", response);
  if (response.basketdata.nrofitems>0) {
    $(".topbar__basket__badge").text(response.basketdata.nrofitems);
    $(".topbar__basket__badge").removeClass("nodisplay");
    let basketurl = $("a.topbar__basket").attr("href");
//console.log("basketurl: ", basketurl);
    if ( (response.basketdata) && (response.basketdata.baskets) && (response.basketdata.baskets.length>0) && ( (basketurl="") || (basketurl="#") ) ) {
      $("a.topbar__basket").attr("href", response.basketdata.baskets[0].basketurl);
    }
  }
  else {
    $(".topbar__basket__badge").addClass("nodisplay");
  }
}

async function _removeArticleFromBasket(_data) {
  let response = await rpc.removeArticleFromBasket(_data) || {};
  //console.log("remocearticlefrombasket", response);
  if (response.basketdata.nrofitems>0) {
    $(".topbar__basket__badge").text(response.basketdata.nrofitems);
    $(".topbar__basket__badge").removeClass("nodisplay");
    let basketurl = $("a.topbar__basket").attr("href");
    if ( (response.basketdata) && (response.basketdata.baskets) && (response.basketdata.baskets.length>0) && ( (basketurl="") || (basketurl="#") ) ) {
      $("a.topbar__basket").attr("href", response.basketdata.baskets[0].basketurl);
    }
  }
  else {
    $(".topbar__basket__badge").addClass("nodisplay");
  }
}

