import './menubar.scss';

import * as dompack from 'dompack';

import $ from 'jquery';

export default class MenuBar {
  // node is the container with class "menubar"
  constructor(node) {
    // cache elements
    this.node = node;
    this.elContainer = node.querySelector('.menubar__container');
    this.menuBottomPadding = 30;

    // set width for main menu items
    const numMainMenuItems = $('.menubar__mainmenu-item').length;
    //$('.menubar__mainmenu-item').css('width', `calc(100% / ${numMainMenuItems})`);

    // enable sub menu items
    $('.menubar__submenu').addClass('show');
let mythis =this;
setTimeout(function() {
    // measure heights
    mythis.navHeight = mythis.elContainer.querySelector('nav').getBoundingClientRect().height + mythis.menuBottomPadding;
    mythis.initialHeight = node.getBoundingClientRect().height;

//console.log({"navheight": mythis.navHeight, "initialheight": mythis.initialHeight, "nav2": $("header .menubar nav").height(), "navelm": $("header .menubar nav") });
    mythis.elContainer.addEventListener('mouseover', () => mythis.open());

    if (dompack.debugflags['menudebug']) {
      mythis.open();
    } else {
      mythis.node.addEventListener('mouseleave', () => mythis.close());
    }
}, 400);
  }

  // set the height of .menubar to the content height
  open() {
    this.node.style.height = `${this.navHeight}px`;
    this.node.classList.add('is-open');
//console.log("open menuheight: ", this.node.style.height);
  }

  // set the height of .menubar to 0
  close() {
    this.node.style.height = `${this.initialHeight}px`;
    this.node.classList.remove('is-open');
//console.log("close menuheight: ", this.node.style.height);
  }
}

//dompack.onDomReady(() => {
dompack.register('.menubar', node => new MenuBar(node));
//});
